<template>
<div v-if="getAllowFullSite" class="main content-page about">

    <div class="content-sidebar vertical-image"></div>

    <div class="content-page-inner">
        <h1>About GAP</h1>
        <p>The Great Awakening Project is a collaborative network of Christian movements, ministries, organizations, leaders, and individuals who are determined to accelerate the cause of Christ’s Kingdom advancement in every Sphere of society in America and around the world. We’re fervently committed to doing our part to catalyze an Awakening in the Church which overflows into a Revival in our society and in continuing Church growth around the world.</p>
        <p>Our vision is to awaken Christians to their need to restore intimacy with God, and to provide training to empower believers for the work of critical cultural transformation in order to see continuing global Kingdom expansion for generations to come.</p>
        <!-- <p>Our mission is to resource and train Christian leaders and believers, engage them in all Seven Spheres of Influence in society, coordinate the prayers of tens of millions of believers nationwide for Awakening and Revival, send 200,000 new missionaries to the last remaining 3.2 billion Unreached Muslims, Hindus, and Buddhists, and to translate the Bible into the 3,821 languages which have little to no Scripture whatsoever.</p> -->
        <p>We believe that through our passion for Christ, unity, repentance, awakening, revival, cultural reformation, evangelism, and missions this unprecedented movement in history could be become the Church’s finest hour!</p>
        <router-link to="/contact"><button class="button-dark last-button">Ready to Connect?</button></router-link>
    </div>

</div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>

.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/about1.jpg");
    background-size: cover;
    background-position: center bottom;
}
// .content-page {
//     padding: 2em 4em;
//     text-align: left;
// }
</style>
